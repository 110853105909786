<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-title>
      Расписание презентаций
    </b-card-title>
    <b-calendar
      locale="ru-RU"
      :readonly="true"
      block
      :date-info-fn="dateClass"
      class="events-calendar"
      @context="onContext"
    />
    <b-list-group
      v-if="events"
      class="events-list"
    >
      <b-list-group-item
        v-for="(event, key) in events"
        :id="`popover-show-${event.id}`"
        :key="key"
        class="d-flex align-items-center"
        @click="() => {
          $emit('bv::show::popover', `popover-show-${event.id}`)
        }"
      >
        <div
          class="event-datetime"
        >
          <div class="event-date">
            {{ event.stDate.split('-')[2].split('T')[0] }}.{{ event.stDate.split('-')[1] }}
          </div>
          <div class="event-time">
            {{ event.stDate.split('T')[1].split(':')[0] }}:{{ event.stDate.split('T')[1].split(':')[1] }}
          </div>
        </div>
        <div>
          <h4
            class="event-title"
            v-html="event.ev_name"
          />
          <div
            class="event-text"
            v-html="event.ev_descr"
          />
        </div>
        <!-- <b-popover
          ref="popover"
          :target="`popover-show-${event.id}`"
          placement="bottom"
          triggers="click"
        >
          <template #title>
            {{ event.ev_name }}
          </template>
          <div class="mt-50">
            Дата начала: {{ event.stDate.split('-')[2].split('T')[0] }}.{{ event.stDate.split('-')[1] }} в {{ event.stDate.split('T')[1].split(':')[0] }}:{{ event.stDate.split('T')[1].split(':')[1] }}
          </div>
          <div class="mt-50">
            Дата завершения: {{ event.endDate.split('-')[2].split('T')[0] }}.{{ event.endDate.split('-')[1] }} в {{ event.endDate.split('T')[1].split(':')[0] }}:{{ event.endDate.split('T')[1].split(':')[1] }}
          </div>
          <hr>
          <div class="mt-50" v-html="event.ev_descr"/>
        </b-popover> -->
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BCard,
  BCardTitle,
  BCalendar,
  BListGroup,
  BListGroupItem,
  BOverlay,
//   BPopover,
//   VBPopover,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardTitle,
    BCalendar,
    BListGroup,
    BListGroupItem,
    BOverlay,
    // BPopover,
  },
//   directives: {
//     'b-popover': VBPopover,
//   },
  props: {
    data: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const month = ref('')
    const events = ref(null)

    const d = ref(null)
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    const onContext = c => {
      month.value = c.activeYMD
    }

    watch([month, d], () => {
      if (d.value) {
        events.value = d.value.filter(item => item.stDate.split('-')[1] === month.value.split('-')[1])
      }
    })

    // eslint-disable-next-line arrow-body-style
    const dateClass = (ymd, date) => {
      return events.value && events.value.find(item => {
        return new Date(item.stDate.split('T')[0]).toLocaleDateString() === date.toLocaleDateString()
      }) ? 'selected' : 'noselected'
    }

    return {
      events,
      dateClass,
      onContext,
      loading,
    }
  },
}
</script>
<style scoped> /** events-calendar styles */
  .events-calendar >>> .b-calendar-header {
    display: none;
  }
  .events-calendar >>> .b-calendar-grid-help {
    display: none;
  }
  .events-calendar >>> .b-calendar-nav button:first-child {
    display: none;
  }
  .events-calendar >>> .b-calendar-nav button:last-child {
    display: none;
  }
  .events-calendar >>> .b-calendar-nav button:nth-child(2) {
    float: left;
  }
  .events-calendar >>> .b-calendar-nav button:nth-child(3) {
    display: none;
  }
  .events-calendar >>> .b-calendar-nav button:nth-child(4) {
    float: right;
  }
  .events-calendar >>> .b-calendar-nav button {
    border-radius: 50%!important;
    width: 32px;
    height: 32px;
    background-color: #F3F2F7;
  }
  .events-calendar >>> .b-calendar-inner {
    position: relative;
  }
  .events-calendar >>> .b-calendar-nav {
    position: absolute;
    width: 100%;
    display: block!important;
    padding-bottom: 24px;
  }
  .events-calendar >>> .b-calendar-grid {
    border: none;
  }
  .events-calendar >>> .border-bottom {
    border-bottom: none!important;
  }
  .events-calendar >>> .b-calendar-grid-caption {
    font-size: 18px;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }
  .events-calendar >>> .b-calendar-grid-weekdays {
    margin-bottom: 30px;
  }
  .events-calendar >>> .b-calendar-grid-weekdays small {
    color: #4e4e4e;
    font-weight: normal;
  }
  .events-calendar >>> .b-calendar-grid-body .col .btn {
    width: 50px;
    height: 50px;
    font-size: 18px;
    font-weight: normal!important;
    line-height: 32px;
  }
  .events-calendar >>> .b-calendar-grid-body .selected .btn {
    background-color: rgba(81, 201, 172, 0.1);
  }
  .events-calendar >>> .b-calendar-grid-body .noselected .btn {
    background-color: transparent;
  }
  .events-calendar >>> .b-calendar-grid-body .selected .btn:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #51C9AC;
    margin-left: auto;
    margin-right: auto;
  }
  .events-calendar >>> .b-calendar-grid-body .btn-light {
    background-color: rgba(81, 201, 172, 0.1);
  }
  .events-calendar >>> .b-calendar-grid-body .rounded-circle {
    border-radius: 6px!important;
  }
  .events-calendar >>> .b-calendar-grid-body .btn-outline-primary{
    border: none!important;
    color: #4b4b4b;
  }

  .event-text >>> * {
    word-break: break-all;
  }
</style>

<style scoped> /** events-list styles */
  .events-list {
    margin-top: 24px;
  }
  .events-list >>> .list-group-item:first-child {
    border-top: none;
  }
  .events-list >>> .list-group-item:last-child {
    border-bottom: none;
  }
  .events-list >>> .list-group-item {
    border-left: none;
    border-right: none;
    border-radius: initial;
    padding-left: 0;
    padding-right: 0;
  }
  .events-list >>> .list-group-item:hover {
    background-color: transparent;
  }
  .events-list .event-datetime {
    background: #51C9AC;
    border-radius: 6px;
    padding: 10px 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 16px;
    min-width: 78px;
    min-height: 54px;
  }
  .events-list .event-date {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
  .events-list .event-time {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .events-list .event-title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
</style>
