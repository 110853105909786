<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-title class="text-center mb-3 mt-2">
      <span>{{ d.name }}</span>
      <div
        v-if="d.leadRank_symbol"
        class="rank-image"
      >
        <small
          class="text-muted"
        >
          Ранг
        </small>
        <b-img
          :src="d.leadRank_symbol"
        />
      </div>
    </b-card-title>
    <hr>
    <b-row>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Вход</small>
          <h4 class="mt-50 mb-0">
            {{ d.enter }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Дата контракта</small>
          <h4 class="mt-50 mb-0">
            {{ d.date }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Статус</small>
          <h4 class="mt-50 mb-0">
            {{ d.status }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Активность</small>
          <h4 :class="`mt-50 mb-0 text-${d.activeColor || 'primary'}`">
            {{ d.active }}
          </h4>
          <b-button
            v-if="d.activeButton == 1"
            :variant="d.activeButtonColor"
            style="margin-top: 10px;"
            @click="activeButton(d.activeButtonMsg)"
          >{{ d.activeButtonText }}</b-button>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Офис</small>
          <h4 class="mt-50 mb-0">
            {{ d.status2 }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Личные приглашения</small>
          <h4 class="mt-50 mb-0" v-html="d.count" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BButton,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BImg,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { root }) {
    const d = ref(JSON.parse(JSON.stringify(props.data)))
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data[0]
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    const activeButton = msg => {
      root.$swal({
        icon: 'info',
        text: msg,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      }).then(() => {
        window.location.replace('/shop')
      })
    }

    return {
      d,
      loading,
      activeButton,
    }
  },
}
</script>
<style>
.rank-image {
  position: absolute;
  right: 16px;
  top: 20px;
}
.rank-image small {
  margin-right: 18px;
}
.rank-image img{
  height: 64px;
}
</style>
