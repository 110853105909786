import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchData(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api
      return axios.get(url, payload)
    },
  },
}
