<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-row v-if="d">
      <!-- <pre>{{ d }}</pre> -->
      <b-col md="12">
        <b-card>
          <b-card-title class="mb-1">
            Структура (чел.):
          </b-card-title>
          <h4 class="badge badge-light-success mb-0" style="font-size: 18px;">{{ d.cntComp }}</h4>
        </b-card>
        <div class="mt-1">
          <div class="d-flex justify-content-between">
            <span>Новые</span>
            <span>
              <span class="mr-75">{{ d.cntCompNew }} чел.</span>
              <span :class="d.dirCompNew === '&darr;' ? 'text-danger' : 'text-success'">
                <span v-html="d.dirCompNew" />
                <span>{{ d.prcCompNew }}%</span>
              </span>
            </span>
          </div>
          <div class="d-flex justify-content-between mt-50">
            <span>Активные</span>
            <span>
              <span class="mr-75">{{ d.cntCompWork }} чел.</span>
              <span :class="d.dirCompWork === '&darr;' ? 'text-danger' : 'text-success'">
                <span v-html="d.dirCompWork" />
                <span>{{ d.prcCompWork }}%</span>
              </span>
            </span>
          </div>
        </div>
      </b-col>
      <b-col md="12" class="mt-1">
        <vue-apex-charts
          v-if="d.chartComp && d.chartComp.data.length"
          height="120"
          :options="{
            ...statisticsProfit.chartOptions,
            xaxis: {
              ...statisticsProfit.chartOptions.xaxis,
              categories: d.chartComp.label,
            },
          }"
          :series="[
            { name: '', data: d.chartComp.data1 },
            { name: '', data: d.chartComp.data },
          ]"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { ref } from '@vue/composition-api'
import store from '@/store'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    VueApexCharts,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'area',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.info, '#ea5455'],
          markers: {
            size: 2,
            colors: [$themeColors.info, '#ea5455'],
            strokeColors: [$themeColors.info, '#ea5455'],
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  setup(props) {
    const d = ref(JSON.parse(JSON.stringify(props.data)))
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data.panelComp
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      d,
      loading,
    }
  },
}
</script>
