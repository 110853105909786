<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-title v-if="d" class="text-center mb-2">
      <div>
        <small class="text-muted font-weight-medium">
          Сеть (текущий период/предыдущий)
        </small>
      </div>
      <div class="mt-50" v-html="d.network" />
    </b-card-title>
    <hr>
    <b-row v-if="d">
      <b-col
        lg="6"
        class="text-center"
      >
        <small class="text-muted font-weight-medium">ЛО пакетный</small>
        <h4 class="mt-50 mb-0 text-success" v-html="d.LOpack" />
      </b-col>
      <b-col
        lg="6"
        class="text-center"
      >
        <small class="text-muted font-weight-medium">ГО пакетный</small>
        <h4 class="mt-50 mb-0" v-html="d.GOpack" />
      </b-col>
      <b-col
        lg="6"
        class="mt-2 text-center"
      >
        <small class="text-muted font-weight-medium">ЛО</small>
        <h4 class="mt-50 mb-0" v-html="d.LO" />
      </b-col>
      <b-col
        lg="6"
        class="mt-2 text-center"
      >
        <small class="text-muted font-weight-medium">ГО</small>
        <h4 class="mt-50 mb-0" v-html="d.GO" />
      </b-col>
    </b-row>
    <hr>
    <b-card-title v-if="d" class="text-center mb-2">
      <div>
        <small class="text-muted font-weight-medium">
          Максимальный ГО за все время
        </small>
      </div>
      <div class="mt-50" v-html="d.GOmax" />
    </b-card-title>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const d = ref(JSON.parse(JSON.stringify(props.data)))
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data[0]
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      d,
      loading,
    }
  },
}
</script>
